.maindivx{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #673AB7;
    height: 60px;
    color: white;
    padding-left: 50px;
    padding-right: 30px;
}
.maindivx>h2{
    color: white;
    margin-top:10px
}