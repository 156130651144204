html.Home {
    background-color: #391656;
  }

.HomeMainDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.imagediv{
    width: 400px;
    height: 300px;
    margin-top: 0.5%;
    background-color: aqua;
}

.buttonlogin{
    background-color: #E91E63;
    width: 190px;
    height: 50px;
    border: none;
    border-radius: 5px;
    margin-top: 6%;
    color: white;
    font-size: x-large;
    cursor: pointer;
}

.buttonsign{
    background-color:#2196F3;
    width: 190px;
    height: 50px;
    border: none;
    border-radius: 5px;
    margin-top: 1.5%;
    color: white;
    font-size: x-large;

}