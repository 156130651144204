.boxmain {
    width: 280px;
    height: 200px;
    background-color: #CD1A57;
    color: white;
    border: none;
    margin-bottom: 10px;
    border-radius: 5px;
    /* Add these lines for the hover effect */
    transition: transform 0.3s;
    transform-origin: center;
    font-size: x-large;
  }
  
  .boxmain:hover {
    /* Change the scale value to control the amount of zoom */
    transform: scale(1.1);
    background-color: rgb(66, 152, 244);
  }
  
  @media (max-width: 768px) {
    .boxmain {
      width: 280px;
    }
  }
  
  