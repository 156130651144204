html.login3 {
    background-color:#523367;
  }

  .buttonsdiv{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    

  }
  
  .button{
    width: 270px;
    height: 70px;
    color: white;
    background-color: #CD1A57;
    border: none;
    border-radius: 5px;
    margin-top: 25px;
    font-size: 30px;
    font-weight: bolder;
    cursor: pointer;
  }

  .supermaindiv{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3%;
    margin-bottom: 4%;
  }