.containers{
    height: 100%;
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
  .innerBox{
    min-width: 480px;
    height: fit-content;
    width: fit-content;
    background-color: #fff;
    box-shadow: 1px 1px 4px rgba(0,0,0,0.2);
    padding: 25px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  
  .footer{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .footer button{
    outline: none;
    border:none;
    background-color: rgb(86, 232, 232);
    color: #fff;
    border-radius: 5px;
    font-weight: bold;
    font-size: 1rem;
    padding: 10px 16px;
    width: 100%;
    transition: 100ms;
    cursor: pointer;
  }
  
  .footer button:hover{
    background-color:rgb(17, 202, 202) ;
  }
  
  .footer p{ 
    font-weight: 700;
    color: #000;
  }
  
  .footer p span a{
    font-weight: bold;
    color: rgb(17, 202, 202);
    letter-spacing: 1px;
    font-size: 1rem;
    text-decoration: none;
  }
  
  .footer button:disabled{
    background-color: gray !important; 
  }
  
  .footer .error{
    font-weight: bold;
    font-size: 0.87rem;
    color:#ff3300;
    text-align:center;
  }
  
  /* Responsive styles for mobile view */
  @media screen and (max-width: 480px) {
    .innerBox {
      min-width: 90%;
      width: 90%;
      padding: 20px;
    }
  }

 .forget{
  cursor: pointer;
 }
  