html.Main {
  background-color:#511A80;
}

.navbar1{
  background-color: #280D41;
  height: 40px;
  display: flex;
  justify-content: right;
  color: white;
  font-size: 13px;
  font-weight: bold;
  align-items: center;
  padding-right: 35px;
}

.navbar1>p{
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.navbar1>span{
  opacity: 0.7;
  height: 20px;
}

.navbar2{
  background-color: #381359;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: medium;
  font-weight: bold;
}

.navbar2 ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.navbar2 li {
  margin-right: 35px;
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 20px;
  height: 18px;
  cursor: pointer;
}

.hamburger span {
  display: block;
  height: 2px;
  background-color: #fff;
}

.navbaroptions {
  position: fixed;
  top: 0;
  right: -100%;
  height: 100vh;
  width: 70vw;
  background-color: #381359;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
 
  justify-content: center;
  padding: 20px;
}

.navbaroptions.open {
  right: 0;
}

.close-icon {
  align-self: flex-end;
  cursor: pointer;
  color: #fff;
}

.hamburger span, .close-icon {
  color: #fff;
}

@media (max-width: 768px) {
  .navbar2 .hamburger {
    display: flex;
  }
}

@media (max-width: 768px) {
  .navbar2 .navbaroptions {
    display: flex;
  }

  .navbar2 {
    padding-right: 40px;
  }
}

.navbaroptions ul {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbaroptions li {
  margin: 10px 0;
  border-bottom: 1px solid #fff; /* Add this line */
  padding-bottom: 10px;
}

@media (min-width: 769px) {
  .navbar2 .navbaroptions {
    position: initial;
    height: auto;
    width: auto;
    background-color: transparent;
    flex-direction: row;
    justify-content: flex-end;
  }

  .navbar2 .navbaroptions ul {
    flex-direction: row;
  }

  .navbar2 .navbaroptions li {
    margin: 0 10px;
  }

  .navbar2 .navbaroptions .close-icon {
    display: none;
  }
  .navbar2 .navbaroptions li {
    margin: 0 10px;
    border-bottom: none; /* Reset the border-bottom property */
    padding-bottom: 0; /* Reset the padding-bottom property */
  }
}
