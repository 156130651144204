.navbarz{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color:#0000FE;
    height: 60px;
    color: white;
    
    padding-right: 30px;

}
.body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4%;
}
html.main1{
    background-color:#5A5BF2;
  }
  .Background{
    background-color: #0000FE;
    height: 250px;
    width: 290px;
  }

  .button1{
    background-color: white;
    color: black;
    width: 290px;
    height: 50px;
    border: none;
    border-radius: 5px;
    margin-top: 4%;
    font-weight: bold;
    font-size: x-large;  
    cursor: pointer;
  }

  .button2{
    background-color:white;
    color: black;
    width: 290px;
    height: 50px;
    border: none;
    border-radius: 5px;
    margin-top: 1%;
    font-weight: bold;
    font-size: x-large;
    cursor: pointer;

  }

  .logout{
    
    color: yellow;
    
  }