* {
    box-sizing: border-box;
  }
  
  body {
    font-family: Arial, sans-serif;
    
  }
  
  .App {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 5%;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 5%;
    
    
  }
  
  h1, h2, h3 {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }
  
  h1 {
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #555;
  }
  
  h3 {
    font-size: 1rem;
    margin-bottom: 5px;
    color: #555;
  }
  
  button {
    background-color:#87CEEB;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
    height: 50px;
    font-weight: bold;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  /* App.css */
  
  /* ...[previous CSS rules remain the same]... */
  
  .calculator {
    display: inline-block;
    background-color: #f0f0f0;
    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
  }
  
  .calculator-display {
    background-color: #2c3e50;
    color: #fff;
    font-size: 1.5rem;
    border: 2px solid #34495e;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .calculator-buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  
  .calculator-buttons button {
    background-color: #3498db;
    padding: 10px;
    font-size: 1.2rem;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .calculator-buttons button:hover {
    background-color: #2980b9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .results {
    background-color: #ecf0f1;
    border: 2px solid #bdc3c7;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
  
  .results h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  
  .results div {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  /* ...[remaining CSS rules stay the same]... */
  
  .questions-container {
    margin-bottom: 20px;
  }
  
  .questions-container ol {
    margin: 0;
    padding: 0;
  }
  
  .questions-container li {
    margin-bottom: 5px;
  }
  
  @media screen and (max-width: 480px) {
    .calculator-buttons {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  .operation{
    margin-top: 30px;
  }
  
  .btnz{
    background-color:#2c3e50;
    margin-top: 10px;
  }
  
  /* App.css */
  
  /* ...[previous CSS rules remain the same]... */
  
  .correct-answer {
    color: #27ae60;
    font-weight: bold;
  }
  
  .incorrect-answer {
    color: #c0392b;
    font-weight: bold;
  }
  
  /* ...[remaining CSS rules stay the same]... */
  html.quiz{
    background-color:#5A5BF2;
  }
  .startbtn{
    width: 40%;
    font-weight: bold;
  }





  .quiz-results-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
  }
  
  .quiz-results-table thead th {
    background-color: #4CAF50;
    color: white;
    padding: 10px;
    text-align: left;
    padding-left: 80px;
  }
  
  .quiz-results-table tbody td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .quiz-results-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  @media screen and (max-width: 600px) {
    .quiz-results-table thead {
      display: none;
    }
  
    .quiz-results-table tr {
      margin-bottom: 10px;
      display: block;
      border-bottom: 2px solid #ddd;
    }
  
    .quiz-results-table td {
      display: block;
      text-align: right;
      padding: 10px;
      text-align: right;
      position: relative;
    }
  
    .quiz-results-table td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 10px;
      font-size: 15px;
      font-weight: bold;
    }
  }
  