.container{
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.container label{
    font-weight: 500;
    font-size: 1rem;
    color: #4b4b4b;
}
.container input{
    border-radius: 5px;
    border: 1px solid #eee; 
    outline: none;
    padding: 10px 15px;
    color: #000;
}
.container input:hover{
    border-color: #ccc;
}

.container input:focus{
    border-color: rgb(17, 202, 202);
}
