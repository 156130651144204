/* selectControl.module.css */
.wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    position: relative;
  }
  
  .label {
    font-weight: 500;
    font-size: 1rem;
    color: #4b4b4b;
  }
  
  .select {
    margin-top: 5px;
    padding-top: 0.6rem;
    padding-bottom: 0.3rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    outline: none;
    background-color: #fff;
    color: #333;
    transition: border-color 0.2s;
  }
  
  .select:focus {
    border-color: rgb(86, 232, 232);
  }
  